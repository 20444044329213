@font-face {
  font-family: "Volte";
  src: url(assets/fonts/Volte-Regular.otf) format("opentype"),
    url(assets/fonts/Volte-Regular.ttf) format("truetype"),
    url(assets/fonts/Volte-Regular.woff) format("woff"),
    url(assets/fonts/Volte-Regular.woff2) format("woff2");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Volte";
  src: url(assets/fonts/Volte-Light.otf) format("opentype"),
    url(assets/fonts/Volte-Light.ttf) format("truetype"),
    url(assets/fonts/Volte-Light.woff) format("woff"),
    url(assets/fonts/Volte-Light.woff2) format("woff2");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Volte";
  src: url(assets/fonts/Volte-Medium.ttf) format("truetype"),
  url(assets/fonts/Volte-Medium.woff) format("woff"),
  url(assets/fonts/Volte-Medium.woff2) format("woff2");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Volte";
  src: url(assets/fonts/Volte-Semibold.otf) format("opentype"),
    url(assets/fonts/Volte-Semibold.ttf) format("truetype"),
    url(assets/fonts/Volte-Semibold.woff) format("woff"),
    url(assets/fonts/Volte-Semibold.woff2) format("woff2");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Kollektif";
  src: url(assets/fonts/Kollektif.ttf) format("truetype");
  font-weight: 400;
  font-dispay: swap;
}

@font-face {
  font-family: "Kollektif";
  src: url(assets/fonts/Kollektif-Bold.ttf) format("truetype");
  font-weight: 700;
  font-dispay: swap;
}